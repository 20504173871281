<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="mb-2">
          <div class="font-weight-medium text-h6">
            {{ $trans("google_calendar_add_title") }}
          </div>
          {{ $trans("google_calendar_add_info") }}
        </div>
        <calendesk-information-message
          v-if="noAccessToCalendarList"
          color="orange"
          additional-class="white--text my-4"
          icon-color="white"
          icon="$alert"
        >
          {{ $trans("google_calendar_permissions_error") }}
        </calendesk-information-message>
        <div class="d-flex align-start">
          <v-text-field
            v-if="noAccessToCalendarList"
            v-model="employeeGoogleCalendarId"
            class="mt-2"
            :label="$trans('google_calendar_id')"
            color="primary"
            :disabled="isSaving"
            outlined
            persistent-hint
            :hint="$trans('google_calendar_example')"
          />
          <v-select
            v-else
            v-model="employeeGoogleCalendarId"
            :items="googleWriteAccessCalendars"
            item-value="id"
            item-text="name"
            class="mt-2"
            :label="$trans('select_calendar')"
            color="primary"
            :disabled="isSaving || isLoading"
            outlined
            hide-details
          />
        </div>
        <div v-if="isEnablingCalendar">
          <v-checkbox
            v-model="pushEventsCheckbox"
            :disabled="isSaving || isLoading"
            :label="$trans('push_all_future_events_to_calendar_checkbox')"
            hide-details
          />
        </div>
        <div class="mt-4 mb-2">
          <div class="font-weight-medium text-h6">
            {{ $trans("calendar_watch_title") }}
          </div>
          {{ $trans("google_calendar_watch_info") }}
        </div>
        <div class="d-flex align-start">
          <v-select
            v-model="employeeWatchGoogleCalendarIds"
            :items="googleReadAccessCalendars"
            item-value="id"
            item-text="name"
            class="mt-2"
            :label="$trans('select_calendars')"
            color="primary"
            :disabled="isSaving || isLoading"
            outlined
            hide-details
            multiple
            small-chips
            deletable-chips
            clearable
          />
        </div>
        <div v-if="employeeWatchGoogleCalendarIds.length > 0">
          <v-checkbox
            v-model="isGoogleCalendarPublic"
            :disabled="isSaving || isLoading"
            hide-details
          >
            <template #label>
              <v-tooltip bottom max-width="400">
                <template #activator="{ on }">
                  <div v-on="on">
                    <span>{{ $trans("calendar_public_label") }}</span>
                  </div>
                </template>
                {{ $trans("calendar_public_tooltip") }}
              </v-tooltip>
            </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center">
        <v-btn
          v-if="!isEnablingCalendar"
          color="error"
          outlined
          :disabled="isLoading || isSaving"
          text
          @click="handleDisconnectFromGoogleCalendar"
        >
          <span v-text="$trans('disconnect_button_title')" />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import api from "@/lib/calendesk-js-library/api/api";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "GoogleCalendar",
  components: { CalendeskInformationMessage },
  mixins: [sharedActions],
  data() {
    return {
      employeeGoogleCalendarId: null,
      employeeWatchGoogleCalendarIds: [],
      isSaving: false,
      isLoading: false,
      pushEventsCheckbox: false,
      noAccessToCalendarList: false,
      isGoogleCalendarPublic: false,
      code: null,
    };
  },
  computed: {
    ...mapGetters({
      googleWriteAccessCalendars: "google/getWriteAccessCalendars",
      googleReadAccessCalendars: "google/getReadAccessCalendars",
      watchedGoogleCalendars: "google/getWatchedCalendars",
    }),
    isEnablingCalendar() {
      return !!this.code;
    },
  },
  created() {
    if (this.getDialog.data && this.getDialog.data.code) {
      this.code = this.getDialog.data.code;
      this.pushEventsCheckbox = true;
      this.enableCalendar();
    } else {
      this.fetchCalendarList();
    }
  },
  methods: {
    ...mapActions({
      updateOwnEmployee: "employee/updateOwn",
      loadAuthUser: "setup/loadAuthUser",
      fetchCalendars: "google/fetchCalendars",
      fetchWatchedEmployeeGoogleCalendars:
        "google/fetchWatchedEmployeeGoogleCalendars",
      updateWatchedEmployeeGoogleCalendars:
        "google/updateWatchedEmployeeGoogleCalendars",
      setRecentlyDisabledByUser: "google/setRecentlyDisabledByUser",
      enableGoogleCalendarForEmployee: "google/enableGoogleCalendarForEmployee",
      getUserData: "auth/getUserData",
      pushEvents: "google/pushEvents",
    }),
    enableCalendar() {
      this.isLoading = true;
      this.$emit("loading", this.isLoading);
      this.enableGoogleCalendarForEmployee({
        code: this.code,
      })
        .then(() => {
          this.fetchCalendarList();
        })
        .catch((error) => {
          errorNotification(null, error);
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
          this.$emit("close");
        });
    },
    fetchCalendarList() {
      this.isLoading = true;
      this.$emit("loading", this.isLoading);

      Promise.all([
        this.fetchCalendars().catch(() => {
          this.noAccessToCalendarList = true;
        }),
        this.fetchWatchedEmployeeGoogleCalendars(),
        this.getUserData(),
      ])
        .then(() => {
          this.setEmployeeCalendar();
        })
        .catch(() => {
          this.setEmployeeCalendar();
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
        });
    },
    setEmployeeCalendar() {
      if (this.loggedUser.employee.google_calendar_id) {
        this.employeeGoogleCalendarId =
          this.loggedUser.employee.google_calendar_id;
      } else if (
        this.googleWriteAccessCalendars &&
        this.googleWriteAccessCalendars.length > 0
      ) {
        const primary = this.googleWriteAccessCalendars.find(
          (calendar) => calendar.is_primary
        );

        if (primary) {
          this.employeeGoogleCalendarId = primary.id;
        }
      } else {
        this.employeeGoogleCalendarId = "primary";
      }

      if (
        this.watchedGoogleCalendars &&
        this.watchedGoogleCalendars.length > 0
      ) {
        this.employeeWatchGoogleCalendarIds = this.watchedGoogleCalendars;
      }

      this.isGoogleCalendarPublic =
        !!this.loggedUser.employee.is_google_calendar_public;
    },
    async handleSave() {
      try {
        this.isSaving = true;
        this.$emit("ctaButtonLoading", true);

        await this.updateOwnEmployee({
          google_calendar_id: this.employeeGoogleCalendarId || null,
          is_google_calendar_public: this.isGoogleCalendarPublic || false,
        });

        await this.updateWatchedEmployeeGoogleCalendars({
          calendar_ids: this.employeeWatchGoogleCalendarIds || [],
        });

        if (this.pushEventsCheckbox) {
          await this.pushEvents();
        }

        await this.loadAuthUser();

        this.setRecentlyDisabledByUser(false);
        successNotification("update_success");
        this.$emit("close");
      } catch (e) {
        this.$emit("ctaButtonLoading", false);
        errorNotification(null, e);
      }

      this.isSaving = false;
    },
    handleDisconnectFromGoogleCalendar() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("google_calendar_disconnect_dialog_title"),
        warning: this.$trans("google_calendar_disconnect_dialog_info"),
        confirmationText: this.$trans("disconnect_button_title"),
        confirmationButtonColor: "error",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          api
            .disableGoogleCalendarForEmployee()
            .then(() => {
              this.setRecentlyDisabledByUser(true);
              this.closeConfirmDialog();
              this.$emit("close");
              successNotification("google_calendar_disconnect_success_message");
            })
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
              this.$emit("close");
            });
        },
      });
    },
  },
};
</script>
